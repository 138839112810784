import { GetServerDataReturn } from 'gatsby';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';

import Button from '../components/button-like/button';
import DirectionsList from '../components/directions-list';
import Layout from '../components/layout';
import TagsList from '../components/tags-list';
import VacanciesList from '../components/vacancies-list';
import { GLOBAL_QUERY } from '../constants';
import { useClassnames } from '../hooks/use-classnames';
import CrossIcon from '../images/cross.svg';
import SearchIcon from '../images/search.svg';
import { TPageProp } from '../types';
import { TStrapiEntity } from '../types/strapi';
import { IGlobalServerRuntimeData } from '../types/strapi/global';
import { IDirection, ITag, IVacancy } from '../types/strapi/vacancies';
import { IVacanciesPage } from '../types/strapi/vacanciesPage';
import { pluralize } from '../utils';

import './vacancies.css';

type TVacancy = Pick<IVacancy, 'id' | 'area' | 'city' | 'jobType' | 'tags' | 'title' | 'direction' | 'isSecret'>;
type TDirection = Pick<TStrapiEntity<IDirection>, 'header' | 'id'>;
type TVacanciesPage = Pick<TStrapiEntity<IVacanciesPage>, 'seo' | 'pageId' | 'isHidden'>;

interface IServerRuntimeData extends IGlobalServerRuntimeData {
    directions: Array<TDirection>,
    vacancies: Array<TVacancy>,
    vacanciesPage: TVacanciesPage
}

type TProps = TPageProp<IServerRuntimeData>;

const Vacancies: React.FC<TProps> = ({ location, serverData }) => {
    const cn = useClassnames();
    const params = new URLSearchParams(location.search);
    const directionParam = params.get('direction');
    const tagsParam = params.get('tags')?.split(',').map((item) => item) || [];

    const $container = useRef<HTMLInputElement>(null);

    const [searchString, setSearchString] = useState('');

    const vacanciesList = serverData.vacancies;
    const directions = serverData.directions;
    const { seo } = serverData.vacanciesPage;

    const [activeDirection, setActiveDirection] = useState<string | null>(directionParam);
    const [activeTags, setActiveTags] = useState<Array<string>>(tagsParam);
    const [filteredVacancies, setFilteredVacancies] = useState<Array<TVacancy>>(vacanciesList);
    const [isMobileFilterVisible, setIsMobileFilterVisible] = useState(false);


    useEffect(() => {
        const newFilteredVacancies = vacanciesList.filter((vacancy) => {
            if(vacancy.isSecret) {
                return false;
            }

            if(activeDirection && activeDirection !== vacancy.direction?.id) {
                return false;
            }

            if(searchString.length && !vacancy.title.toLowerCase().includes(searchString.toLowerCase().trim())) {
                return false;
            }

            if(activeTags.length) {
                return vacancy.tags?.filter(({ id }) => activeTags.includes(id)).length === activeTags.length;
            }

            return true;
        });

        setFilteredVacancies(newFilteredVacancies);
    }, [searchString, activeDirection, activeTags, vacanciesList]);

    const filtersCount = useMemo(() => (activeDirection ? 1 : 0) + activeTags?.length || 0, [activeDirection, activeTags]);
    const filteredTags = useMemo(() => {
        return filteredVacancies.reduce<Array<ITag>>((acc, item) => {
            item.tags?.forEach((tag) => {
                if(!acc.find((tagInAcc) => tagInAcc.id === tag.id) && (activeDirection || activeTags.length)) {
                    acc.push(tag);
                }
            });

            return acc;
        }, []);
    }, [filteredVacancies]);

    const onClickDirection = useCallback((e) => {
        const selectedDirectionId = e.target.getAttribute('data-id');

        if(!selectedDirectionId) {
            setActiveDirection(null);

            return;
        }

        if(activeDirection !== selectedDirectionId) {
            setActiveDirection(selectedDirectionId);

            return;
        }

        setActiveDirection(null);
    }, [activeDirection]);

    const onClickTag = useCallback((e) => {
        const selectedTagId = e.target.getAttribute('data-id');

        if(!activeTags.includes(selectedTagId)) {
            setActiveTags([...activeTags, selectedTagId]);

            return;
        }

        setActiveTags(activeTags.filter((item) => item !== selectedTagId));
    }, [activeTags]);

    return (
        <div className={cn('vacancies__page')}>
            <Layout
                seo={{
                    ...seo,
                    shareImage: undefined
                }}
                navPanel={serverData.navPanel}
                global={serverData.global}
                respondForm={serverData.respondForm}
            >
                <div className={cn('vacancies-page__wrapper')}>
                    {
                        !isMobileFilterVisible ? (
                            <React.Fragment>
                                <DirectionsList directions={directions} count={filteredVacancies.length} activeDirection={activeDirection} onClickDirection={onClickDirection} />
                                <div className={cn('vacancies__wrapper')}>
                                    <div className={cn('vacancies__search-wrapper')}>
                                        <input
                                            ref={$container}
                                            className={cn('vacancies__search')}
                                            value={searchString}
                                            type="text"
                                            disabled={!filteredVacancies.length && !searchString}
                                            placeholder={isMobile ? 'Вакансии' : 'Поиск'}
                                            onChange={(e) => setSearchString(e.target.value)}
                                        />
                                        {(searchString) ? (
                                            <img className={cn('vacancies__search-icon')} src={CrossIcon} onClick={() => setSearchString('')} />
                                        ) : (
                                            <img className={cn('vacancies__search-icon')} src={SearchIcon} onClick={() => $container.current?.focus()} />
                                        )}
                                    </div>
                                    <div className={cn('vacancies__tags-wrapper')}>
                                        <TagsList tags={filteredTags} activeTags={activeTags} onClickTag={onClickTag} />
                                    </div>
                                    <VacanciesList searchString={searchString} data={filteredVacancies} activeTags={activeTags} onClickTag={onClickTag} />
                                </div>
                                <div className={cn('vacancies__filter-buttons')}>
                                    <Button
                                        variant="secondary"
                                        type="button"
                                        className={cn('vacancies__button', 'vacancies__filter-button', {
                                            'vacancies__button_visible': !isMobileFilterVisible
                                        })}
                                        label="Фильтровать"
                                        isBlock={true}
                                        onClick={() => {
                                            window.scrollTo(0, 0);
                                            setIsMobileFilterVisible(!isMobileFilterVisible);
                                        }}
                                    >
                                        Фильтровать
                                        {filtersCount ? (
                                            <span className={cn('vacancies__button-count')}>
                                                {filtersCount}
                                            </span>
                                        ) : null}
                                    </Button>
                                </div>
                            </React.Fragment>
                        ) : (
                            <div className={cn('vacancies__filter-wrapper', {
                                'vacancies__filter-wrapper_visible': isMobileFilterVisible
                            })}
                            >
                                <p className={cn('vacancies__filter-header')}>Фильтр</p>

                                <DirectionsList directions={directions} count={filteredVacancies.length} activeDirection={activeDirection} onClickDirection={onClickDirection} />

                                {filteredTags?.length ? (
                                    <React.Fragment>
                                        <p className={cn('vacancies__filter-header')}>Теги</p>
                                        <TagsList tags={filteredTags} activeTags={activeTags} onClickTag={onClickTag} />
                                    </React.Fragment>
                                ) : null}
                                <div className={cn('vacancies__filter-buttons')}>
                                    <Button
                                        type="button"
                                        className={cn('vacancies__button', {
                                            'vacancies__button_visible': isMobileFilterVisible
                                        })}
                                        disabled={!filteredVacancies.length}
                                        isBlock={true}
                                        onClick={() => {
                                            window.scrollTo(0, 0);
                                            setIsMobileFilterVisible(!isMobileFilterVisible);
                                        }}
                                    >
                                        {!filteredVacancies.length ? 'Нет вакансий' : `Смотреть ${filteredVacancies.length} ${pluralize(filteredVacancies.length, 'вакансию', 'вакансии', 'вакансий')}`}
                                    </Button>

                                    <Button
                                        variant="secondary"
                                        type="button"
                                        className={cn('vacancies__button', 'vacancies__reset-button', {
                                            'vacancies__button_visible': isMobileFilterVisible
                                        })}
                                        isBlock={true}
                                        onClick={() => {
                                            window.scrollTo(0, 0);

                                            if(filtersCount === 0) {
                                                setIsMobileFilterVisible(!isMobileFilterVisible);

                                                return;
                                            }
                                            setActiveDirection(null);
                                            setActiveTags([]);
                                        }}
                                    >
                                        {filtersCount ? 'Сбросить' : 'Закрыть'}

                                        {filtersCount ? (
                                            <span className={cn('vacancies__button-count')}>
                                                {filtersCount}
                                            </span>
                                        ) : null}
                                    </Button>
                                </div>
                            </div>
                        )
                    }
                </div>
            </Layout>
        </div>
    );
};

export default Vacancies;

const query = `
  query getVacancies($locale: String) {
    ${GLOBAL_QUERY}
    vacanciesPage(locale: $locale) {
      seo {
        metaTitle
        metaDescription
      }
      pageId
      isHidden
    }
    vacancies(where: {isSecret: false, isArchived: false}, locale: $locale) {
      id
      locale
      area {
        text
        value
        id
      }
      city {
        text
        value
        id
      }
      jobType {
        text
        value
        id
      }
      tags {
        text
        value
        id
      }
      title
      direction {
        header
        id
      }
      id
    }
    directions(locale: $locale) {
      header
      id
    }
  }
`;

export async function getServerData(): Promise<GetServerDataReturn<IServerRuntimeData>> {
    try {
        const variables = JSON.stringify({
            locale: process.env.GATSBY_LOCALE_CODE
        });
        const response = await fetch(
            `${process.env.GATSBY_API_URL}/graphql?query=${query}&variables=${variables}`
        );

        const data: { data: IServerRuntimeData } = await response.json();

        if(data.data.vacanciesPage.isHidden) {
            return {
                status: 404
            };
        }

        return {
            props: {
                vacanciesPage: data.data.vacanciesPage,
                vacancies    : data.data.vacancies,
                directions   : data.data.directions,
                navPanel     : data.data.navPanel,
                global       : data.data.global,
                respondForm  : data.data.respondForm,
                footer       : data.data.footer
            }
        };
    } catch(error) {
        return {
            status: 500
        };
    }
}
